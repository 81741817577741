<template>
  <div
    class="max-height-0 min-height-inherit overflow-hidden position-sticky top-70 force-width-400 hover-scroll"
  >
    <div class="d-flex flex-column mb-4" style="width: 360px">
      <agency-need-card
        :need="need"
        v-for="need in needs"
        :key="need._id"
        :hideButtons="true"
        class="mt-4"
        :onClicked="onSelected"
      />
      <div v-if="!needs.length" class="mt-10 mx-10">
        No needs found.
        <div v-if="profileType == 'Agency'">
          Please create new needs.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AgencyNeedCard from "../agency/needs/AgencyNeedCard.vue";

export default {
  components: { AgencyNeedCard },
  methods: {
    onSelected(need) {
      if (this.$route.query._id != need) {
        this.$router.replace({ query: { _id: need } });
      }
    },
  },
  computed: {
    ...mapGetters({
      needs: "need/getAllNeeds",
      profileType: "auth/getType",
    }),
  },
};
</script>
<style scoped></style>
