<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="d-flex flex-row"
      >
        <need-map-contents-section />
        <need-map-right-section class="hidden-sm-and-down" />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import NeedMapContentsSection from "./NeedMapContentsSection.vue";
import NeedMapRightSection from "./NeedMapRightSection.vue";
export default {
  components: {
    NeedMapRightSection,
    NeedMapContentsSection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: [],
    };
  },
};
</script>
<style scoped></style>
